<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Category</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="category-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            type="text"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="category-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        name: null,
        slug: null,
      },
      category: null,
      errors: {},
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    openForm: function(category = null) {
      if (category !== null) {
        this.isEditing = true;
        this.category = category;
        this.fields.name = category.name;
        this.fields.slug = category.slug;
      }

      this.dialog = true;
    },

    resetForm: function() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;
      this.fields.slug = null;
      this.category = null;
      this.errors = {};
    },

    saveForm: function() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.categoryId = this.category.id;
      }

      this.$store
        .dispatch("watson/species/saveCategory", payload)
        .then(() => {
          this.resetForm();
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    thumbnailHasChanged: function() {
      this.fields.thumbnail_has_changed = true;
    },
  },
};
</script>
